.panel_container {
  padding: 50px 0;
  background: #ffffff;
  border: 1px solid #f2f4f7;

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 30px;

  > * {
    margin: 0;
    padding: 0 50px;

  @media (max-width: 450px) {
    padding: 0 10px;
  }
  }



  :global(.ant-checkbox-group) {
    flex-direction: column;
    gap: 16px 0;

    > :global(.ant-checkbox-group-item) {
      margin: 0;
    }
  }

  :global(.ant-picker) {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 5px;
    width: 100%;
  }

  :global(.ant-input-number) {
    width: 100%;
  }
}

.panel_collapse {
  border: 1px solid #f2f4f7;
  display: flex;
  flex-direction: column;
  gap: 10px;

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  :global(.ant-collapse-item) {
    border-bottom: none;
    background: #ffffff;
    border-radius: 8px !important;

    > :global(.ant-collapse-header) {
      background-color: #13a2a2;
      border: 1px solid #ededed;
      border-radius: 8px !important;
      height: 60px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      > :global(.ant-collapse-header-text) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;

        color: #ffffff;
      }

      > :global(.ant-collapse-expand-icon) {
        height: 24px;
        width: 24px;
        background: white;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding-inline-start: 0px !important;

        > span {
          > * {
            fill: #13a1a1;
          }
        }
      }
    }

    > :global(.ant-collapse-content) {
      border-top: none;
      border-radius: 0 0 8px 8px;
      > :global(.ant-collapse-content-box) {
        padding: 0;
      }
    }

    &:global(.ant-collapse-item-active) {
      > :global(.ant-collapse-header) {
        border-radius: 8px 8px 0px 0px !important;
      }
    }
  }
}

.personal_info {
  padding: 30px;
  background: #f1f7f7;
  border-radius: 5px;
  position: relative;

  @media (max-width: 450px) {
    padding: 3px;
  }
  &:after {
    background-image: url("../../../statics/images/personal-bg-right.png");
    right: 0;
    bottom: 0;
    top: 0;
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    width: 250px;
  }

  > div {
    > p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      color: #111111;

      > span {
        color: #13a1a1;
        font-weight: 600;
      }
    }
  }

  > ul {
    list-style: inside;

    > li {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      color: #111111;

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }
}

.schedule_basic_info {
  > table {
    width: 100%;
    border-bottom: 1px solid #f2f4f7;

    > thead {
      background-color: #e2f2f2;
      word-break: keep-all;

      > tr > th {
        padding: 12px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;

        color: #111111;

        text-align: left;
      }
    }

    tbody {
      > tr {
        > td {
          padding: 12px;
          word-break: break-word;

          &:first-child {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;

            color: #111111;
          }

          &:nth-child(2) {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #111111;
            word-break: break-word;
          }
        }
      }
    }
  }

  :global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      margin: 0;

      & * {
        font-weight: 600;
        font-size: 13px;
        color: #666666;
      }

      &::before {
        display: none;
      }

      :global(.ant-tabs-ink-bar) {
        display: none;
      }

      :global(.ant-tabs-tab-active) {
        background: #03abab;
        & > * {
          color: #ffffff !important;
        }
      }

      :global(.ant-tabs-nav-list) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        overflow: hidden;
        border-radius: 8px 8px 0px 0px;

        & > :global(.ant-tabs-tab) {
          margin: 0;

          &:first-of-type {
            border-top-left-radius: 8px;
            border-left: 1px solid #ededed;
          }

          &:nth-of-type(4) {
            border-top-right-radius: 8px;
          }

          border-top: 1px solid #ededed;

          border-right: 1px solid #ededed;

          &:global(.ant-tabs-tab-active) {
            border: none;
          }
        }

        & > * {
          & > * {
            width: 100%;
            text-align: center;
          }
        }

        &::after {
          content: none;
        }
      }
    }

    :global(.ant-tabs-content) {
      border: 1px solid #03abab;
      border-radius: 0px 0px 8px 8px;
      overflow: hidden;

      :global(.ant-tabs-tabpane) {
        > div {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(auto, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          overflow: hidden;

          > div {
            padding: 24px 16px;

            &:nth-child(n - 5) {
              border-bottom: 1px solid #ededed;
            }

            &:not(:nth-child(4n)) {
              border-right: 1px solid #ededed;
            }

            > div {
              display: flex;
              align-items: center;

              > p {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;

                color: #111111;
                margin: 0;
              }
              margin-bottom: 8px;
            }

            > p {
              &:nth-child(2) {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;

                color: #111111;
                margin: 0;
              }
            }
          }

          > p {
            grid-column: 1/5;
            margin: 30px;

            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;

            color: #111111;

            text-align: center;
          }
        }
      }
    }
  }
}

.celsius_account_balances {
  border: 1px solid #ededed;
  padding: 30px;
  border-radius: 8px;

  > .title {
    display: flex;
    align-items: center;
    > span {
      &:first-child {
        background: #037c7c;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        color: #fff;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      &:last-child {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        color: #111111;
      }
    }
  }

  > .edit_table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;


    > thead > tr > th {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;

      color: #111111;
      text-align: left;
      padding: 12px;
    }

    > tbody > tr > td {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      color: #111111;

      padding: 12px;

      border-bottom: 1px solid #ededed;

      > div {
        display: flex;
      }
    }
  }

  > .empty_message {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: #666666;

    margin-top: 16px;
    margin-bottom: 16px;
  }

  > .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    border-bottom: 1px solid #ededed;

    > p {
      margin: 0;

      &:first-child {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #111111;
      }

      &:last-child {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;

        color: #111111;
      }
    }

    &:last-child {
      border: none;
    }
  }

  > .add_button {
    box-shadow: none;
    height: 48px;
    width: 100%;
    background: #e2f2f2;
    border-radius: 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #03abab;
    border: none;
    margin-top: 15px;
  }
}

.account_pref_risk {
  height: 64px;

  background: #e2f2f2;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  > p {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    &:first-child {
      color: #111111;
    }

    &:last-child {
      color: #03abab;
    }
  }
}

.navigation {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;

  & > * {
    background: white;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    height: 48px;
    padding: 10px 30px;
    &:last-of-type {
      background: #03abab;
      color: #ffffff;
    }
  }
}
.modal-container {
  :global(.ant-modal-content) {
    padding: 30px;
  }
}

.form-container {
  z-index: 1;

  > :global(.ant-form-item) {
    margin-bottom: 0;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }

    > h2 {
      margin: 0;
    }

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
    }

    :global(.ant-input),
    :global(.ant-input-number) {
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;
      width: 100%;

      :global(.ant-input-number-input-wrap) {
        height: 100%;

        :global(.ant-input-number-input) {
          height: 100%;
          padding: 0;
        }
      }
    }

    :global(.ant-select) {
      height: 48px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;

      :global(.ant-select-selector) {
        height: 100%;
      }

      :global(.ant-select-selection-item) {
        line-height: 48px;
      }
    }

    :global(.ant-picker) {
      height: 48px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;
      width: 100%;
    }

    button {
      width: 100%;
      height: 48px;
      border-radius: 5px;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      box-shadow: none;

      &:first-of-type {
        color: #ffffff;
        background-color: #03abab;

        &:hover {
          background-color: #037c7c;
        }
      }

      &:nth-of-type(2) {
        margin-top: 10px;
        border: 1px solid #e2e2e2;
        color: #111111;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}

:global(.ant-picker-ok) {
  > button {
    background-color: #037c7c;
  }
}

.account-balances {
  .block {
    margin-bottom: 30px;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      display: inline-block;
      margin-bottom: 16px;
    }

    .tx-table {
      .table-row {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 1px solid #ededed;
      }
    }
  }
  :global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      margin: 0;

      & * {
        font-weight: 600;
        font-size: 13px;
        color: #666666;
      }

      &::before {
        display: none;
      }

      :global(.ant-tabs-ink-bar) {
        display: none;
      }

      :global(.ant-tabs-tab-active) {
        background: #03abab;
        & > * {
          color: #ffffff !important;
        }
      }

      :global(.ant-tabs-nav-list) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        overflow: hidden;
        border-radius: 8px 8px 0px 0px;

        & > :global(.ant-tabs-tab) {
          margin: 0;

          &:first-of-type {
            border-top-left-radius: 8px;
            border-left: 1px solid #ededed;
          }

          &:nth-last-of-type(2) {
            border-top-right-radius: 8px;
          }

          border-top: 1px solid #ededed;

          border-right: 1px solid #ededed;

          &:global(.ant-tabs-tab-active) {
            border: none;
          }
        }

        & > * {
          & > * {
            width: 100%;
            text-align: center;
          }
        }

        &::after {
          content: none;
        }
      }
    }

    :global(.ant-tabs-content) {
      border: 1px solid #03abab;
      border-radius: 0px 0px 8px 8px;
      overflow: hidden;
    }
  }
}

.modal_limit {
  :global {
    width: 350px;
    .ant-modal-content {
      padding: 0px;
      .ant-modal-header {
        padding: 22px 0px;
        background: #13a1a1;
      }
      .title-txt {
        text-align: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        /* identical to box height, or 100% */

        /* Grey/White */

        color: #ffffff;
      }
      .txt-desc {
        padding: 0px 15px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        /* or 167% */

        display: flex;
        align-items: center;

        /* Grey/Grey 500 */

        color: #111111;
      }
    }
    .button {
      width: 120px;
      height: 48px;
      color: white;
      /* Primary/Primary 200 */
      margin: 0 auto;
      background: #03abab;
      border-radius: 5px;
      margin:17px ;
    }
  }
}

